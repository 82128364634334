
let stocktrimPrices = {
    A: {
        small: { monthly: { id: "stocktrim-small-a-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "stocktrim-medium-a-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "stocktrim-large-a-AUD-Monthly", price: 0 } },
    },
    B: {
        small: { monthly: { id: "stocktrim-small-b-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "stocktrim-medium-b-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "stocktrim-large-b-AUD-Monthly", price: 0 } },
    },
    C: {
        small: { monthly: { id: "stocktrim-small-c-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "stocktrim-medium-c-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "stocktrim-large-c-AUD-Monthly", price: 0 } },
    },
    D: {
        small: { monthly: { id: "stocktrim-small-d-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "stocktrim-medium-d-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "stocktrim-large-d-AUD-Monthly", price: 0 } },
    }
};

let solboxPrices = {
    A: {
        small: { monthly: { id: "solbox-small-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "solbox-medium-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "solbox-large-AUD-Monthly", price: 0 } },
    },
    B: {
        small: { monthly: { id: "solbox-small-b-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "solbox-medium-b-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "solbox-large-b-AUD-Monthly", price: 0 } },
    },
    C: {
        small: { monthly: { id: "solbox-small-c-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "solbox-medium-c-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "solbox-large-c-AUD-Monthly", price: 0 } },
    },
    D: {
        small: { monthly: { id: "solbox-small-d-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "solbox-medium-d-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "solbox-large-d-AUD-Monthly", price: 0 } },
    }
};

let vintracePrices = {
    A: {
        small: { monthly: { id: "vintrace-small-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "vintrace-medium-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "vintrace-large-AUD-Monthly", price: 0 } },
    },
    B: {
        small: { monthly: { id: "vintrace-small-b-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "vintrace-medium-b-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "vintrace-large-b-AUD-Monthly", price: 0 } },
    },
    C: {
        small: { monthly: { id: "vintrace-small-c-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "vintrace-medium-c-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "vintrace-large-c-AUD-Monthly", price: 0 } },
    },
    D: {
        small: { monthly: { id: "vintrace-small-d-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "vintrace-medium-d-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "vintrace-large-d-AUD-Monthly", price: 0 } },
    }
};

let sellercloudPrices = {
    A: {
        small: { monthly: { id: "sellercloud-small-a-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "sellercloud-medium-a-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "sellercloud-large-a-AUD-Monthly", price: 0 } },
    },
    B: {
        small: { monthly: { id: "sellercloud-small-b-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "sellercloud-medium-b-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "sellercloud-large-b-AUD-Monthly", price: 0 } },
    },
    C: {
        small: { monthly: { id: "sellercloud-small-c-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "sellercloud-medium-c-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "sellercloud-large-c-AUD-Monthly", price: 0 } },
    },
    D: {
        small: { monthly: { id: "sellercloud-small-d-AUD-Monthly", price: 0 } },
        medium: { monthly: { id: "sellercloud-medium-d-AUD-Monthly", price: 0 } },
        large: { monthly: { id: "sellercloud-large-d-AUD-Monthly", price: 0 } },
    }
};

let pipelinerPrices = {
    A: {
        small: { yearly: { id: "pipeliner-small-a-AUD-Yearly", price: 0 } },
        medium: { yearly: { id: "pipeliner-medium-a-AUD-Yearly", price: 0 } },
        large: { yearly: { id: "pipeliner-large-a-AUD-Yearly", price: 0 } },
    },
    B: {
        small: { yearly: { id: "pipeliner-small-b-AUD-Yearly", price: 0 } },
        medium: { yearly: { id: "pipeliner-medium-b-AUD-Yearly", price: 0 } },
        large: { yearly: { id: "pipeliner-large-b-AUD-Yearly", price: 0 } },
    },
    C: {
        small: { yearly: { id: "pipeliner-small-c-AUD-Yearly", price: 0 } },
        medium: { yearly: { id: "pipeliner-medium-c-AUD-Yearly", price: 0 } },
        large: { yearly: { id: "pipeliner-large-c-AUD-Yearly", price: 0 } },
    },
    D: {
        small: { yearly: { id: "pipeliner-small-d-AUD-Yearly", price: 0 } },
        medium: { yearly: { id: "pipeliner-medium-d-AUD-Yearly", price: 0 } },
        large: { yearly: { id: "pipeliner-large-d-AUD-Yearly", price: 0 } },
    }
};




const erps = {
    TestA: "test_a",
    TestB: "test_b",
    TestC: "test_c",
    TestD: "test_d",
    Access: "access",
    Acumatica: "acumatica",
    BusinessCentral: "businesscentral",
    CartonCloud: "cartoncloud",
    Cin7: "cin7",
    Commerce7: "commerce7",
    Datapel: "datapel",
    Epicor: "epicor",
    Dear: "dear",
    MyobOnline: "myob-online",
    MyobAdv: "myob-adv",
    MyobEssentials: "myob-essentials",
    MyobExo: "myob-exo",
    MyobGreentree: "myob-greentree",
    MyobPremier: "myob-premier",
    Tradegecko: "tradegecko",
    Unleashed: "unleashed",
    Vintrace: "vintrace",
    Wiise: "wiise",
    WooCommerce: "woocommerce",
    Xero: "xero",
    Zoho: "zoho",
    Tencia: "tencia",
    Storefeeder: "storefeeder",
    Spire: "spire",
    Shopify: "shopify",
    SapHana: "saphana",
    SapB1: "sapb1",
    SageOne: "sageone",
    SageBC: "sagebc",
    Sage300: "sage300",
    Sage200: "sage200",
    Sage100: "sage100",
    Sage50: "sage50",
    Reckon: "reckon",
    QuickbooksEnterprise: "quickbooks-enterprise",
    QuickbooksOnline: "quickbooks-online",
    Pronto: "pronto",
    Pepperi: "pepperi",
    Odoo: "odoo",
    Netsuite: "netsuite",
    Neto: "neto",
    DynamicsNAV: "dynamicsnav",
    Maropost: "maropost",
    Jiwa: "jiwa",
    Jcurve: "jcurve",
    Freshbooks: "freshbooks",
    Fishbowl: "fishbowl",
}

const erpOptions = [
    // {
    //     value: erps.TestA,
    //     text: "TEST A",
    //     hasTemplate: false,
    //     img: `neo-logo.png`,
    //     type: "a",
    //     cbitem: "setup-system-type-a-AUD",
    //     blueprint: "",
    //     isDefault: false,
    // },
    // {
    //     value: erps.TestB,
    //     text: "TEST B",
    //     hasTemplate: false,
    //     img: `neo-logo.png`,
    //     type: "b",
    //     cbitem: "setup-system-type-b-AUD",
    //     blueprint: "",
    //     isDefault: false,
    // },
    // {
    //     value: erps.TestC,
    //     text: "TEST C",
    //     hasTemplate: false,
    //     img: `neo-logo.png`,
    //     type: "c",
    //     cbitem: "setup-system-type-c-AUD",
    //     blueprint: "",
    //     isDefault: false,
    // },
    // {
    //     value: erps.TestD,
    //     text: "TEST D",
    //     hasTemplate: false,
    //     img: `neo-logo.png`,
    //     type: "d",
    //     cbitem: "setup-system-type-d-AUD",
    //     blueprint: "",
    //     isDefault: false,
    // },
    {
        value: erps.Access,
        text: "Access One",
        hasTemplate: false,
        img: `erp-logos_0041_access.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Acumatica,
        text: "Acumatica",
        hasTemplate: false,
        img: `erp-logos_0040_acumatica.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.BusinessCentral,
        text: "Business Central",
        hasTemplate: false,
        img: `erp-logos_0039_businesscentral.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.CartonCloud,
        text: "Carton Cloud",
        hasTemplate: false,
        img: `erp-logos_0038_cartoncloud.jpg`,
        type: "c",
        cbitem: "setup-system-type-c-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Cin7,
        text: "CIN7",
        hasTemplate: false,
        img: `erp-logos_0037_cin7.jpg`,
        type: "c",
        cbitem: "setup-system-type-c-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Commerce7,
        text: "Commerce7",
        hasTemplate: false,
        img: `erp-logos_0036_commerce7.jpg`,
        type: "b",
        cbitem: "setup-system-type-b-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Datapel,
        text: "Datapel",
        hasTemplate: false,
        img: `erp-logos_0035_datapel.jpg`,
        type: "c",
        cbitem: "setup-system-type-c-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Epicor,
        text: "Epicor",
        hasTemplate: false,
        img: `erp-logos_0034_epicor.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Dear,
        text: "Dear Inventory",
        hasTemplate: false,
        img: `erp-logos_0000_dear.jpg`,
        type: "c",
        cbitem: "setup-system-type-c-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.MyobOnline,
        text: 'MYOB AccountRight',
        hasTemplate: false,
        img: `erp-logos_0028_myob.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.MyobAdv,
        text: 'MYOB Advanced',
        hasTemplate: false,
        img: `erp-logos_0028_myob.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.MyobEssentials,
        text: 'MYOB Essentials',
        hasTemplate: false,
        img: `erp-logos_0028_myob.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.MyobExo,
        text: 'MYOB EXO',
        hasTemplate: false,
        img: `myob-exo.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.MyobGreentree,
        text: 'MYOB GreenTree',
        hasTemplate: false,
        img: `erp-logos_0028_myob.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.MyobPremier,
        text: 'MYOB Premier',
        hasTemplate: false,
        img: `erp-logos_0028_myob.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Tradegecko,
        text: "TradeGecko",
        hasTemplate: false,
        img: `erp-logos_0007_tradegecko.jpg`,
        type: "c",
        cbitem: "setup-system-type-c-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Unleashed,
        text: "Unleashed",
        hasTemplate: false,
        img: `erp-logos_0006_unleashed.jpg`,
        type: "c",
        cbitem: "setup-system-type-c-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Vintrace,
        text: "Vintrace",
        hasTemplate: false,
        img: `erp-logos_0005_vintrace.jpg`,
        type: "c",
        cbitem: "setup-system-type-c-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Wiise,
        text: "Wiise",
        hasTemplate: false,
        img: `erp-logos_0004_wiise.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.WooCommerce,
        text: "Woo Commerce",
        hasTemplate: false,
        img: `erp-logos_0003_woocommerce.jpg`,
        type: "b",
        cbitem: "setup-system-type-b-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Xero,
        text: 'Xero',
        hasTemplate: false,
        img: `erp-logos_0002_xero.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Zoho,
        text: "Zoho",
        hasTemplate: false,
        img: `erp-logos_0001_zoho.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Tencia,
        text: "Tencia",
        hasTemplate: false,
        img: `erp-logos_0008_tencia.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Storefeeder,
        text: "Storefeeder",
        hasTemplate: false,
        img: `erp-logos_0009_storefeeder.jpg`,
        type: "b",
        cbitem: "setup-system-type-b-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Spire,
        text: "Spire",
        hasTemplate: false,
        img: `erp-logos_0010_spire.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Shopify,
        text: "Shopify",
        hasTemplate: false,
        img: `erp-logos_0011_shopify.jpg`,
        type: "b",
        cbitem: "setup-system-type-b-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.SapHana,
        text: "SAP Hana",
        hasTemplate: false,
        img: `erp-logos_0012_saphana.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.SapB1,
        text: "SAP B1",
        hasTemplate: false,
        img: `erp-logos_0013_sapb1.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.SageOne,
        text: "Sage One",
        hasTemplate: false,
        img: `erp-logos_0014_sageone.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.SageBC,
        text: "Sage Business Cloud",
        hasTemplate: false,
        img: `erp-logos_0015_sagebc.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Sage300,
        text: "Sage 300",
        hasTemplate: false,
        img: `erp-logos_0016_sage300.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Sage200,
        text: "Sage 200",
        hasTemplate: false,
        img: `erp-logos_0017_sage200.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Sage100,
        text: "Sage 100",
        hasTemplate: false,
        img: `erp-logos_0018_sage100.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Sage50,
        text: "Sage 50",
        hasTemplate: false,
        img: `erp-logos_0019_sage50.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Reckon,
        text: "Reckon",
        hasTemplate: false,
        img: `erp-logos_0020_reckon.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.QuickbooksEnterprise,
        text: "Quickbooks Enterprise",
        hasTemplate: false,
        img: `erp-logos_0021_quickbooks.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.QuickbooksOnline,
        text: "Quickbooks Online",
        hasTemplate: false,
        img: `erp-logos_0021_quickbooks.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Pronto,
        text: "Pronto",
        hasTemplate: false,
        img: `erp-logos_0022_pronto.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Pepperi,
        text: "Pepperi",
        hasTemplate: false,
        img: `erp-logos_0023_pepperi.jpg`,
        type: "b",
        cbitem: "setup-system-type-b-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Odoo,
        text: "Odoo",
        hasTemplate: false,
        img: `erp-logos_0024_odoo.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Netsuite,
        text: "Netsuite",
        hasTemplate: false,
        img: `erp-logos_0025_netsuite.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Neto,
        text: "Neto",
        hasTemplate: false,
        img: `erp-logos_0026_neto.jpg`,
        type: "b",
        cbitem: "setup-system-type-b-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.DynamicsNAV,
        text: "Microsoft Dynamics",
        hasTemplate: false,
        img: `erp-logos_0027_dynamicsnav.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Maropost,
        text: "Maropost",
        hasTemplate: false,
        img: `erp-logos_0029_maropost.jpg`,
        type: "b",
        cbitem: "setup-system-type-b-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Jiwa,
        text: "JIWA",
        hasTemplate: false,
        img: `erp-logos_0030_jiwa.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Jcurve,
        text: "jCurve",
        hasTemplate: false,
        img: `erp-logos_0031_jcurve.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Freshbooks,
        text: "Freshbooks",
        hasTemplate: false,
        img: `erp-logos_0032_freshbooks.jpg`,
        type: "a",
        cbitem: "setup-system-type-a-AUD",
        blueprint: "",
        isDefault: false,
    },
    {
        value: erps.Fishbowl,
        text: "Fishbowl",
        hasTemplate: false,
        img: `erp-logos_0033_fishbowl.jpg`,
        type: "d",
        cbitem: "setup-system-type-d-AUD",
        blueprint: "",
        isDefault: false,
    },
];

erpOptions.sort((a, b) => (a.text.toUpperCase() > b.text.toUpperCase()) ? 1 : ((b.text.toUpperCase() > a.text.toUpperCase()) ? -1 : 0))

let billPeriods = [
    { value: "monthly", text: "Monthly", per: "/mo" },
    { value: "yearly", text: "Yearly", per: "/year" },
];

const stocktrimErps = erpOptions.map(x => {
    let obj = { ...x };

    switch (obj.type) {
        case "a": obj.pricing = stocktrimPrices.A; break;
        case "b": obj.pricing = stocktrimPrices.B; break;
        case "c": obj.pricing = stocktrimPrices.C; break;
        case "d": obj.pricing = stocktrimPrices.D; break;
    }

    switch (obj.value) {
        case erps.MyobOnline:
            obj.blueprint = "stocktrim-20663519";
            obj.hasTemplate = true;
            obj.isDefault = true;
            break;
        case erps.MyobExo:
            obj.blueprint = "stocktrim-37038093";
            obj.hasTemplate = true;
            break;
        default:
            obj.blueprint = "stocktrim-47917132";
            obj.hasTemplate = true;
    }
    return obj;
});

const solboxErps = erpOptions.map(x => {
    let obj = { ...x };

    switch (obj.type) {
        case "a": obj.pricing = solboxPrices.A; break;
        case "b": obj.pricing = solboxPrices.B; break;
        case "c": obj.pricing = solboxPrices.C; break;
        case "d": obj.pricing = solboxPrices.D; break;
    }

    switch (obj.value) {
        case erps.MyobOnline:
            obj.blueprint = "solbox-61937301";
            obj.hasTemplate = true;
            obj.isDefault = true;
            break;
        case erps.Xero:
            obj.blueprint = "solbox-45731003";
            obj.hasTemplate = true;
            break;
        default:
            obj.blueprint = "solbox-80158688";
            obj.hasTemplate = true;
        // case erps.TestA:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
        // case erps.TestB:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
        // case erps.TestC:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
        // case erps.TestD:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
    }
    return obj;
});

const vintraceErps = erpOptions.filter(x => x.value !== "vintrace").map(x => {
    let obj = { ...x };

    switch (obj.type) {
        case "a": obj.pricing = vintracePrices.A; break;
        case "b": obj.pricing = vintracePrices.B; break;
        case "c": obj.pricing = vintracePrices.C; break;
        case "d": obj.pricing = vintracePrices.D; break;
    }

    switch (obj.value) {
        case erps.WooCommerce:
            obj.blueprint = "vintrace-97865530";
            obj.hasTemplate = true;
            obj.isDefault = true;
            break;
        case erps.Shopify:
            obj.blueprint = "vintrace-54838415";
            obj.hasTemplate = true;
            break;
        default:
            obj.blueprint = "vintrace-63153666";
            obj.hasTemplate = true;
        // case erps.TestA:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
        // case erps.TestB:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
        // case erps.TestC:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
        // case erps.TestD:
        //     obj.blueprint = "";
        //     obj.hasTemplate = true;
        //     break;
    }
    return obj;
});

const sellercloudErps = erpOptions.filter(x => x.value !== "sellercloud").map(x => {
    let obj = { ...x };

    switch (obj.type) {
        case "a": obj.pricing = sellercloudPrices.A; break;
        case "b": obj.pricing = sellercloudPrices.B; break;
        case "c": obj.pricing = sellercloudPrices.C; break;
        case "d": obj.pricing = sellercloudPrices.D; break;
    }

    switch (obj.value) {
        default:
            obj.blueprint = "sellercloud-10880010";
            obj.hasTemplate = true;
    //     case erps.TestA:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    //     case erps.TestB:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    //     case erps.TestC:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    //     case erps.TestD:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    }
    return obj;
});

const pipelinerErps = erpOptions.filter(x => x.value !== "pipeliner").map(x => {
    let obj = { ...x };

    switch (obj.type) {
        case "a": obj.pricing = pipelinerPrices.A; break;
        case "b": obj.pricing = pipelinerPrices.B; break;
        case "c": obj.pricing = pipelinerPrices.C; break;
        case "d": obj.pricing = pipelinerPrices.D; break;
    }

    switch (obj.value) {
        default:
            obj.blueprint = "pipeliner-11405088";
            obj.hasTemplate = true;
    //     case erps.TestA:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    //     case erps.TestB:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    //     case erps.TestC:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    //     case erps.TestD:
    //         obj.blueprint = "";
    //         obj.hasTemplate = true;
    //         break;
    }
    return obj;
});


let systems = [
    {
        id: "test",
        name: "Test",
        logo: "neo-logo.png",
        erps: [
            {
                value: "test",
                text: "test",
                hasTemplate: true,
                img: `neo-logo.png`,
                type: "a",
                cbitem: "Setup-Test-A-AUD",
                blueprint: "",
                isDefault: true,
            }
        ],
        plans: [
            { period: "monthly", tier: "small", type: "a", cbitem: "Test-Plan-A-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "a", cbitem: "Test-Plan-A-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "a", cbitem: "Test-Plan-A-AUD-Monthly" },
        ]
    },
    {
        id: "stocktrim",
        name: "StockTrim",
        logo: "stocktrim.jpeg",
        erps: stocktrimErps,
        tiers: {
            small: "Starter",
            medium: "Growth",
            large: "Ultra",
            enterprise: "Enterprise"
        },
        plans: [
            { period: "monthly", tier: "small", type: "a", cbitem: "stocktrim-small-a-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "b", cbitem: "stocktrim-small-b-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "c", cbitem: "stocktrim-small-c-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "d", cbitem: "stocktrim-small-d-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "a", cbitem: "stocktrim-medium-a-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "b", cbitem: "stocktrim-medium-b-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "c", cbitem: "stocktrim-medium-c-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "d", cbitem: "stocktrim-medium-d-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "a", cbitem: "stocktrim-large-a-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "b", cbitem: "stocktrim-large-b-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "c", cbitem: "stocktrim-large-c-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "d", cbitem: "stocktrim-large-d-AUD-Monthly" },
        ]
    },
    {
        id: "solbox",
        name: "SolBox",
        logo: "erp-logos-solbox.jpg",
        erps: solboxErps,
        tiers: {
            small: "1-4 Users",
            medium: "5-20 Users",
            large: "20+ Users",
            enterprise: "Custom"
        },
        plans: [
            { period: "monthly", tier: "small", type: "a", cbitem: "solbox-small-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "b", cbitem: "solbox-small-b-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "c", cbitem: "solbox-small-c-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "d", cbitem: "solbox-small-d-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "a", cbitem: "solbox-medium-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "b", cbitem: "solbox-medium-b-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "c", cbitem: "solbox-medium-c-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "d", cbitem: "solbox-medium-d-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "a", cbitem: "solbox-large-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "b", cbitem: "solbox-large-b-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "c", cbitem: "solbox-large-c-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "d", cbitem: "solbox-large-d-AUD-Monthly" },
        ]
    },
    {
        id: "vintrace",
        name: "Vintrace",
        logo: "erp-logos_0005_vintrace.jpg",
        erps: vintraceErps,
        tiers: {
            small: "Small",
            medium: "Estate",
            large: "Crush",
            enterprise: "Enterprise"
        },
        plans: [
            { period: "monthly", tier: "small", type: "a", cbitem: "vintrace-small-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "b", cbitem: "vintrace-small-b-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "c", cbitem: "vintrace-small-c-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "d", cbitem: "vintrace-small-d-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "a", cbitem: "vintrace-medium-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "b", cbitem: "vintrace-medium-b-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "c", cbitem: "vintrace-medium-c-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "d", cbitem: "vintrace-medium-d-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "a", cbitem: "vintrace-large-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "b", cbitem: "vintrace-large-b-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "c", cbitem: "vintrace-large-c-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "d", cbitem: "vintrace-large-d-AUD-Monthly" },
        ]
    },
    {
        id: "sellercloud",
        name: "Sellercloud",
        logo: "sellercloud-logo.png",
        erps: sellercloudErps,
        tiers: {
            small: "Base",
            medium: "Pro",
            large: "Corp",
            enterprise: "Custom"
        },
        plans: [
            { period: "monthly", tier: "small", type: "a", cbitem: "sellercloud-small-a-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "b", cbitem: "sellercloud-small-b-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "c", cbitem: "sellercloud-small-c-AUD-Monthly" },
            { period: "monthly", tier: "small", type: "d", cbitem: "sellercloud-small-d-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "a", cbitem: "sellercloud-medium-a-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "b", cbitem: "sellercloud-medium-b-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "c", cbitem: "sellercloud-medium-c-AUD-Monthly" },
            { period: "monthly", tier: "medium", type: "d", cbitem: "sellercloud-medium-d-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "a", cbitem: "sellercloud-large-a-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "b", cbitem: "sellercloud-large-b-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "c", cbitem: "sellercloud-large-c-AUD-Monthly" },
            { period: "monthly", tier: "large", type: "d", cbitem: "sellercloud-large-d-AUD-Monthly" },
        ]
    },
    {
        id: "pipeliner",
        name: "Pipeliner",
        logo: "pipeliner-logo.png",
        erps: pipelinerErps,
        billPeriod: billPeriods[1],
        tiers: {
            small: "Starter",
            medium: "Business",
            large: "Enterprise",
            enterprise: "Custom"
        },
        plans: [
            { period: "yearly", tier: "small", type: "a", cbitem: "pipeliner-small-a-AUD-Yearly" },
            { period: "yearly", tier: "small", type: "b", cbitem: "pipeliner-small-b-AUD-Yearly" },
            { period: "yearly", tier: "small", type: "c", cbitem: "pipeliner-small-c-AUD-Yearly" },
            { period: "yearly", tier: "small", type: "d", cbitem: "pipeliner-small-d-AUD-Yearly" },
            { period: "yearly", tier: "medium", type: "a", cbitem: "pipeliner-medium-a-AUD-Yearly" },
            { period: "yearly", tier: "medium", type: "b", cbitem: "pipeliner-medium-b-AUD-Yearly" },
            { period: "yearly", tier: "medium", type: "c", cbitem: "pipeliner-medium-c-AUD-Yearly" },
            { period: "yearly", tier: "medium", type: "d", cbitem: "pipeliner-medium-d-AUD-Yearly" },
            { period: "yearly", tier: "large", type: "a", cbitem: "pipeliner-large-a-AUD-Yearly" },
            { period: "yearly", tier: "large", type: "b", cbitem: "pipeliner-large-b-AUD-Yearly" },
            { period: "yearly", tier: "large", type: "c", cbitem: "pipeliner-large-c-AUD-Yearly" },
            { period: "yearly", tier: "large", type: "d", cbitem: "pipeliner-large-d-AUD-Yearly" },
        ]
    },
];

export { systems, billPeriods };