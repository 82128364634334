import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBGIZh1i8W2mt4O6c4sWQvioUu4Vx4ind8",
  authDomain: "neonetworks-chargebee.firebaseapp.com",
  projectId: "neonetworks-chargebee",
  storageBucket: "neonetworks-chargebee.appspot.com",
  messagingSenderId: "492971276370",
  appId: "1:492971276370:web:5c7ca1adc247957e8c478b"
};

// Initialize Firebase
const fapp = initializeApp(firebaseConfig);
export default fapp;