<template>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
    <symbol id="check" viewBox="0 0 16 16">
      <title>Check</title>
      <path
        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    </symbol>
  </svg>

  <!-- Begin page content -->
  <main class="flex-shrink-0" id="app" v-cloak>
    <section class="text-center container">
      <div class="row py-lg-5">
        <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
          <div style="display: flex; justify-content: center; align-items: center">
            <div class="system-container">
              <img v-if="system" :src="require(`@/assets/imgs/${system.logo}`)" alt="Neo Logo" style="width: 100px" />
            </div>
            <div class="system-connection">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-link"
                viewBox="0 0 16 16">
                <path
                  d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                <path
                  d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
              </svg>
            </div>
            <div class="system-container">
              <img v-if="myModel" :src="require(`@/assets/imgs/${myModel.img}`)" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-8 mx-auto">
          <h1 class="fw-light">{{ system?.name }}</h1>
          <p class="lead text-muted">
            Pick the ERP System from the dropdown and choose the plan below.
          </p>

          <select class="form-select" @change="changeSystem($event)">
            <optgroup label="Instant Connection">
              <template v-for="myOption in myOptions" :key="myOption.value">
                <option v-if="myOption.hasTemplate" :value="myOption.value" :selected="myOption.value == myModel.value">
                  {{ myOption.text }}
                </option>
              </template>
            </optgroup>
            <optgroup label="Advanced Connection">
              <template v-for="myOption in myOptions" :key="myOption.value">
                <option v-if="!myOption.hasTemplate" :value="myOption.value"
                  :selected="myOption.value == myModel.value">
                  {{ myOption.text }}
                </option>
              </template>
            </optgroup>
          </select>

          <br />

          <!-- <div v-show="myModel.hasTemplate">
            <p class="lead text-muted">Billing Period</p>

            <select class="form-select" @change="changePeriod($event)">
              <template v-for="bp in billPeriods" :key="bp.value">
                <option :value="bp.value">{{ bp.text }}</option>
              </template>
            </select>
          </div> -->
        </div>
      </div>
    </section>

    <div class="container" v-if="myModel">
      <div v-show="!myModel.hasTemplate" class="row mb-3 text-center">
        <p>
          The connection between
          <span style="font-weight: bold">{{ myModel?.text }}</span> and
          <span style="font-weight: bold">{{ system?.name }}</span> requires a
          customised setup. Please contact us for details.
        </p>
        <p>
          <a href="mailto:support@neonetworks.com.au" class="w-50 btn btn-lg btn-primary">Contact Us</a>
        </p>
      </div>

      <div v-show="myModel.hasTemplate" class="row row-cols-1 row-cols-md-4 mb-3 text-center">
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">{{ system.tiers.small }}</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ${{ myModel.pricing.small[myBillPeriod.value].price }}<small class="text-muted fw-light">{{
                    myBillPeriod.per
                }}</small>
              </h1>

              <br />

              <!-- <div id="div-tier1"></div> -->
              <a href="javascript:void(0)" class="w-100 btn btn-lg btn-primary"
                v-on:click="openCheckout('small')">Subscribe</a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">{{ system.tiers.medium }}</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ${{ myModel.pricing.medium[myBillPeriod.value].price }}<small class="text-muted fw-light">{{
                    myBillPeriod.per
                }}</small>
              </h1>
              <br />

              <!-- <div id="div-tier2"></div> -->
              <a href="javascript:void(0)" class="w-100 btn btn-lg btn-primary"
                v-on:click="openCheckout('medium')">Subscribe</a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm border-primary">
            <div class="card-header py-3 text-white bg-primary border-primary">
              <h4 class="my-0 fw-normal">{{ system.tiers.large }}</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ${{ myModel.pricing.large[myBillPeriod.value].price }}<small class="text-muted fw-light">{{
                    myBillPeriod.per
                }}</small>
              </h1>
              <br />

              <!-- <div id="div-tier3"></div> -->
              <a href="javascript:void(0)" class="w-100 btn btn-lg btn-primary"
                v-on:click="openCheckout('large')">Subscribe</a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">{{ system.tiers.enterprise }}</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                POA
              </h1>
              <br />

              <!-- <div id="div-tier2"></div> -->
              <a href="mailto:gk@neonetworks.com.au" class="w-100 btn btn-lg btn-primary">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mb-4">
        <a href="javascript:void(0)" data-cb-type="portal">Manage your subscriptions</a>
      </div>
    </div>
  </main>
</template>

<script>
import * as conf from "../sysconfig";
import chargebeeReady from "../chargebee.js";
import firebase from "../firebaseInit";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const db = getFirestore(firebase);

export default {
  name: "System",
  data() {
    return {
      system: null,
      systemId: this.$route.params.systemid,
      myModel: null,
      myOptions: null,
      billPeriods: conf.billPeriods,
      myBillPeriod: conf.billPeriods[0],
      cart: null,
      cbInstance: null,
      itemPrices: [],
    };
  },
  setup() { },
  mounted() {
    this.getSystem();

    chargebeeReady.then(() => {
      console.log("LOADED");
      this.cbInstance = window.Chargebee.init({
        site: "neo-networks",
        isItemsModel: true,
      });
      window.Chargebee.registerAgain();

      this.cart = this.cbInstance.getCart();
    });

  },
  methods: {
    async getItemPrices() {
      const querySnapshot = await getDocs(collection(db, "itemprices"));
      this.itemPrices = [];
      for (const doc of querySnapshot.docs) {
        this.itemPrices.push({ id: doc.id, data: doc.data() });
      }
    },
    async getSystem() {

      await this.getItemPrices();

      this.system = conf.systems.find((s) => s.id === this.systemId);
      if (!this.system) {
        this.$router.push("/");
      } else {
        // console.log(JSON.stringify(this.system), this.systemId);
        this.myOptions = this.system.erps;
        this.myModel =
          this.system.erps.find((x) => x.isDefault) || this.system.erps[0];

        if (this.system.billPeriod) {
          this.myBillPeriod = this.system.billPeriod;
        }

        this.updatePrices();
        this.updateForm();
      }
    },
    updatePrices() {
      const per = this.myBillPeriod.value;

      let prSmall = this.myModel.pricing.small[per];
      prSmall.price = this.itemPrices.find(x => x.id == prSmall.id)?.data.price / 100;

      let prMedium = this.myModel.pricing.medium[per];
      prMedium.price = this.itemPrices.find(x => x.id == prMedium.id)?.data.price / 100;

      let prLarge = this.myModel.pricing.large[per];
      prLarge.price = this.itemPrices.find(x => x.id == prLarge.id)?.data.price / 100;
    },
    changeSystem(event) {
      let sys = event.target.value;
      this.myModel = this.system.erps.find((x) => x.value === sys);
      this.updatePrices();
      this.updateForm();
    },
    changePeriod(event) {
      let period = event.target.value;
      this.myBillPeriod = conf.billPeriods.find((x) => x.value === period);
      this.updateForm();
    },
    updateForm() {
      if (window.Chargebee) {
        window.Chargebee.registerAgain();
      }
    },
    openCheckout(tier) {
      let sysType = this.myModel.type;
      let cbTier = this.system.plans.find(
        (s) =>
          s.tier === tier &&
          s.type === sysType &&
          s.period === this.myBillPeriod.value
      );
      const planPriceId = cbTier.cbitem;
      const planPriceQuantity = 1;

      const product = this.cbInstance.initializeProduct(
        planPriceId,
        planPriceQuantity
      );
      this.cart.replaceProduct(product);

      product.addCharge({
        id: this.myModel.cbitem,
        quantity: 1,
      });

      product.setCustomData({ cf_bb_blueprint_id: this.myModel.blueprint });

      this.cart.proceedToCheckout();
    },
  },
};
</script>